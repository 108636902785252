<template>
  <el-container>
    <el-header class="navbar">
      <a class="navbar-logo">
        <span> 蝌蚪物联网 </span>
      </a>
      <el-menu
        class="navbar-nav"
        mode="horizontal"
        style="border: 0"
        background-color="#001529"
      ></el-menu>
      <ul class="navbar-right">
        <li>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              {{ $store.state.user ? $store.state.user.username : "" }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item  command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </el-header>
    <el-container class="main-container">
      <el-aside width="250px" class="aside">
        <el-menu
          class="side-menu"
          :router="true"
          :default-active="defaultActiveMenu"
          background-color="#ffffff"
          text-color="#262626"
          active-text-color="#ffffff"
        >
          <el-menu-item index="/">
            <i class="el-icon-s-home"></i> 首页
          </el-menu-item>
          <el-menu-item index="/apps">
            <i class="el-icon-s-grid"></i> 应用管理
          </el-menu-item>
          <el-submenu index="2">
            <template slot="title"><i class="el-icon-cpu"></i> 设备</template>

            <el-menu-item index="/products">产品管理 </el-menu-item>
            <el-menu-item index="/devices">设备管理 </el-menu-item>
            <el-menu-item index="/groups">分组管理 </el-menu-item>
            <!-- <el-menu-item index="/cameras">视频监控 </el-menu-item> -->
          </el-submenu>
          <!-- <el-menu-item index="/data"><i class="el-icon-data-analysis"></i> 数据 </el-menu-item>
          <el-submenu index="4">
            <template slot="title"
              ><i class="el-icon-cpu"></i> 人工智能</template
            >
            <el-menu-item index="/data">人脸识别</el-menu-item>
            <el-menu-item index="/data">语音识别</el-menu-item>
          </el-submenu>
          <el-menu-item index="/data"
            ><i class="el-icon-monitor"></i> 设备监控</el-menu-item
          >
          <el-menu-item index="/ui"
            ><i class="el-icon-mobile"></i> 界面</el-menu-item
          >
          <el-menu-item index="/scene"
            ><i class="el-icon-connection"></i> 场景联动</el-menu-item
          > -->
        </el-menu>
      </el-aside>
      <el-main class="main-content">
        <transition>
          <router-view></router-view>
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      ...mapState["user"],
      menu: [],
      defaultActiveMenu: "/",
    };
  },
  mounted() {
    this.initUser();
  },
  methods: {
    initUser() {
      this.$api.auth
        .getCurrentUser()
        .then((res) => {
          this.$store.commit("SAVE_CURRENT_USER", res);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    handleCommand(command) {
      switch (command) {
        case "logout":
          this.$store.commit("LOGOUT");
          this.$router.replace({
            name: "login",
          });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.side-menu {
  height: calc(100vh - 60px);
  background-color: #fafafa;
  position: absolute;
  width: 250px;

  .el-menu-item.is-active {
    background-color: #409eff !important;
  }
}

.el-dropdown-link {
  cursor: pointer;
  color: #ffffff;
}

.navbar {
  position: fixed;
  width: 100%;
  display: flex;
  background-color: #001529;
  z-index: 100;

  .navbar-logo {
    width: 230px;
    overflow: hidden;
    float: left;
    height: 60px;
    line-height: 60px;
    text-decoration: none;
    white-space: nowrap;
    color: #ffffff;
    font-size: 18px;
  }

  .navbar-nav {
    flex: 1;
  }

  .navbar-right {
    margin: 16px 0;
    padding: 0;
    list-style-type: none;

    li {
      display: inline-block;
      margin: 0 16px;
    }
  }
}
.main-container {
  margin: 60px 0 0 0;
}
.aside {
  position: fixed;
  left: 0;
  top: 60px;
  height: 100%;
  width: 230px;
}
.main-content {
  background-color: #f0f2f5;
  margin-left: 250px;
}
</style>
